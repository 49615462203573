#hero-img {
    display: grid;
    place-items: center;
    
}
.contact-form {
    background-image: url('../../assets/split_background.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    margin: 0;
    padding: 0;
    height: 100vh;    
    width: 100%;
    position: absolute;
    top: -25;
    left: 0;
    right: 0;
    padding-top: 20vh;
    
    
}

.banner {
    color: black;
    display: flex;
    justify-content: center;
}


  .input {
    background-color: white;
    opacity: 100%;
    border-radius: 45px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    width: 50vw;
    
  }
