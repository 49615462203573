

.main {
   
    background-image: url(../../assets/HomeBG.webp);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-position: center center;

}



.content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3rem;
    color: black;
    
    
    

}

.content, h1 {
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

