.ReactProjects-container {
  background-image: url(../../assets/reactProjectsBackground.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 20vh;
}

.ReactProjects-wrapper {
  height: 100vh;
}

.card-style {
  margin-top: auto;
  padding-bottom: 50px;
  margin: auto;
}

.imdb {
  height: 5rem;
  width: 5rem;
}

.card-text {
  font-size: 1.5rem;
}

.title {
  display: flex;
  justify-content: center;
}

.ReactProjects-title {
  padding-bottom: 5rem;
}
