.Projects-container {
  background-image: url("../../assets/project-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Projects-wrapper {
  height: 100vh;
}



.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5rem;
  gap: 7rem;
}

.card-styles {
  width: 35rem;
  height: 100%; /* Set the height to 100% to fill the space */
  border: solid 2px black;
  box-shadow: 10px -10px black;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column; /* Make the content stack vertically */
}

.title {
  text-align: center;
  padding-bottom: 5rem;
  margin: 0;
}

.link {
  display: flex;
  justify-content: space-around;
}

.link a {
  text-decoration: none;
  color: #333;
  margin: 1rem;
  background-color: #f0f0f0;
}

.link a:hover {
  background-color: #210fe7;
}
