.resume {
    display: flex;
    justify-content: center;
    padding: 15vh;
    height: 100vh;
    background-color: gray;
    
}
.cv-image {
    border: 1px solid black;
    margin: 3rem;
    height: 80vh;
}
