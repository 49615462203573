/* Removes the drop down arrow */
    .dropdown-toggle::after {
    display: none !important; 
  } 



  * {
    
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
}


.card-link {
  display: flex;
  justify-content:space-evenly;
}

html {
  font-size: 62.5%;
}


/* .main-content {
  padding-top: 116px;
} */
