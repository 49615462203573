

.grad-text {
    display: grid;
    place-items:center;
    background: linear-gradient(to right, #FFCC99,  #E0B0FF);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-stroke: .2px white;
    
  }

  .about-text {
    border: 1px solid black;
    text-align: center;
   width: 75vw;
    padding: 5rem;
    padding-top: 1.5rem;
    box-shadow: 10px -10px black
}
.about-content {
    font-size: 1.9rem;
    text-align: justify;
    text-indent: 50px;
    text-decoration: none;
    color: #f4e8e8;
}

.tech-stack {
    padding: 5rem;
    z-index: 1;
}

.tech-logo {
    display: grid;
    place-items: center;
    padding: 5px;
    
}

.about {
    background-image: url(../../assets/about-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
   
    background-position:  center center;
    background-attachment: fixed;
    padding-top: 20vh;
}
